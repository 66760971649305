import React, { Component } from "react";
import { useLocation, withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import axios from "axios";
// import { withTranslation } from "react-i18next";
const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}

class App extends Component {
  state = {};
  componentDidMount() {
    this.onRouteChanged();
    const token = localStorage.getItem("token");
    axios
      .get(baseUrl + `me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.userType === "Admin") {
          this.setState({
            usertype: "Admin",
            userAname: res.data.name,
            dashboard: true,
            total: true,
            earning: true,
            roomcode: true,
            allAdmins: true,
            newAdmin: true,
            allUsers: true,
            sitesettings: true,
            newUser: true,
            pendingKyc: true,
            completedKyc: true,
            rejectKyc: true,
            allChallenges: true,
            completedChallenges: true,
            conflictChallenges: true,
            cancelledChallenges: true,
            runningChallenges: true,
            newChallenge: true,
            penaltyBonus: true,
            depositHistory: true,
            bonusHistory: true,
            withdrawlHistory: true,
            allWithdrawlRequests: true,
            allDepositRequests: true,
            pages: true,
            bonusReport: true, //Added by Team
            penaltyReport: true, //Added by Team
            withdrawalReport: true, //Added by Team
            depositReport: true, //Added by Team
            dropChallenges: true,
          });
        } else if (res.data.userType === "Agent") {
          this.setState({
            usertype: "Agent",
            userAname: res.data.name,
            dashboard: res.data.permissions[0].status,
            total: res.data.permissions[0].status,
            earning: res.data.permissions[1].status,
            roomcode: res.data.permissions[1].status,
            allAdmins: res.data.permissions[2].status,
            newAdmin: res.data.permissions[3].status,
            allUsers: res.data.permissions[4].status,
            newUser: res.data.permissions[5].status,
            pendingKyc: res.data.permissions[6].status,
            completedKyc: res.data.permissions[7].status,
            rejectKyc: res.data.permissions[8].status,
            allChallenges: res.data.permissions[9].status,
            completedChallenges: res.data.permissions[10].status,
            conflictChallenges: res.data.permissions[11].status,
            cancelledChallenges: res.data.permissions[12].status,
            runningChallenges: res.data.permissions[13].status,
            newChallenge: res.data.permissions[14].status,
            penaltyBonus: res.data.permissions[15].status,
            depositHistory: res.data.permissions[16].status,
            withdrawlHistory: res.data.permissions[17].status,
            allWithdrawlRequests: res.data.permissions[18].status,
            allDepositRequests: res.data.permissions[19].status,
            pages: res.data.permissions[20].status,
            bonusHistory: res.data.permissions[21].status,
            bonusReport: res.data.permissions[22].status, //Added by Team
            withdrawalReport: res.data.permissions[23].status, //Added by Team
            depositReport: res.data.permissions[24].status, //Added by Team
            penaltyReport: res.data.permissions[25].status, //Added by Team
            // dropChallenges: res.data.permissions[26].status,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    let navbarComponent = !this.state.isFullPageLayout ? (
      <Navbar usertype={this.state.usertype} userAname={this.state.userAname} />
    ) : (
      ""
    );
    // let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? (
      <Sidebar
        usertype={this.state.usertype}
        dashboard={this.state.dashboard}
        total={this.state.total}
        earning={this.state.earning}
        roomcode={this.state.roomcode}
        allAdmins={this.state.allAdmins}
        newAdmin={this.state.newAdmin}
        sitesettings={this.state.sitesettings} //Added by Team
        allUsers={this.state.allUsers}
        newUser={this.state.newUser}
        pendingKyc={this.state.pendingKyc}
        completedKyc={this.state.completedKyc}
        rejectKyc={this.state.rejectKyc}
        allChallenges={this.state.allChallenges}
        completedChallenges={this.state.completedChallenges}
        conflictChallenges={this.state.conflictChallenges}
        cancelledChallenges={this.state.cancelledChallenges}
        runningChallenges={this.state.runningChallenges}
        newChallenge={this.state.newChallenge}
        penaltyBonus={this.state.penaltyBonus}
        depositHistory={this.state.depositHistory}
        bonusHistory={this.state.bonusHistory}
        withdrawlHistory={this.state.withdrawlHistory}
        allWithdrawlRequests={this.state.allWithdrawlRequests}
        allDepositRequests={this.state.allDepositRequests}
        pages={this.state.pages}
        bonusReport={this.state.bonusReport} //Added by Team
        penaltyReport={this.state.penaltyReport} //Added by Team
        withdrawalReport={this.state.withdrawalReport} //Added by Team
        depositReport={this.state.depositReport} //Added by Team
        dropChallenges={this.state.dropChallenges}
      />
    ) : (
      ""
    );
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    return (
      <div
        className="container-scroller"
        // style={{ border: "3px solid blue" }}
      >
        {sidebarComponent}
        <div
          className=" page-body-wrapper"
          // style={{ border: "3px solid gold" }}
        >
          {navbarComponent}
          <div
            className="main-panel"
            // style={{ border: "3px solid green" }}
          >
            <div
              className="content-wrapper"
              // style={{
              //   backgroundColor: "rgba(216, 221, 242)",
              //   border: "2px solid red",
              // }}
            >
              <AppRoutes />
            </div>
            {/* {footerComponent} */}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/user-pages/login-1",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
      "/landing",
      "/login",
      "/register",
      "/adminlogin",
      "/home",
      "/profile",
      "/help",
      "/Deposit",
      "/",
      "/Homepage/pOPULAR",
      "/KYC/update-pan",
      "/KYC/update-aadhar",
      "/Games",
      "/Referral-history",
      `/landing/:id`,
      "/wallet",
      "/support",
      "/Withdrawopt",
      "/Addcase",
      "/Addfunds",
      "/Notification",
      "/refer",
      "/transaction",
      "//transaction-history",
      "/web",
      "/return",
      "/redeem/refer",
      "/transaction-history",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
    if (
      this.props.location.pathname.split("/")[1] == "landing" ||
      this.props.location.pathname.split("/")[1] == "viewgame1"
    ) {
      this.setState({
        isFullPageLayout: true,
      });
      document
        .querySelector(".page-body-wrapper")
        .classList.add("full-page-wrapper");
    }
  }
}

export default withRouter(App);
