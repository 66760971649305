import React, { useState, useEffect } from "react";

import axios from "axios";

const Gateway = () => {
  const [razorPayout, setRazorpayout] = useState(true);
  const [razorDeposit, setRazorDeposit] = useState(true);
  const [razorpayAuto, setRazorpayAuto] = useState(false);
  const [razorPayKey, setRazorpayKey] = useState();
  const [razorPaySecretKey, setRazorpaysecretKey] = useState();
  const [buttonColor, setButtonColor] = useState("#34445e");
  const [accountName, setAccountName] = useState();
  const [PhoneStatus, setPhoneStatus] = useState(true);
  const [PhoneMID, setPhoneMID] = useState(true);
  const [phonePeKey, setPhonePeKey] = useState(false);
  const [upiGateway, setUpiGateway] = useState(false);
  const [upiGatewayKey, setUpiGatewayKey] = useState("");
  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    const data = axios.get(baseUrl + "gatewaysettings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId(res.data._id ? res.data._id : "");
      setRazorpayout(res.data.razorPayout);
      setRazorDeposit(res.data.razorDeposit);
      setRazorpayAuto(res.data.razorpayAuto);
      setPhoneStatus(res.data.phonePeKey);
      setPhoneMID(res.data.PhoneMID);
      setPhonePeKey(res.data.phonePeKey);
      setRazorpayKey(res.data.razorPayKey);
      setRazorpaysecretKey(res.data.razorPaySecretKey);
      setAccountName(res.data.accountName);
      setUpiGateway(res.data.upiGateway);
      setUpiGatewayKey(res.data.upiGatewayKey);
    });
  }, []);

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const response = await axios.post(baseUrl + `gatewaysettings`, {
      settingId,
      razorPayout,
      razorDeposit,
      razorpayAuto,
      PhoneStatus,
      PhoneMID,
      phonePeKey,
      razorPayKey,
      razorPaySecretKey,
      accountName,
      upiGateway,
      upiGatewayKey,
    });
    console.log(response.data.status);
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  return (
    <>
      <div className="row ">
        <div className="col-12 grid-margin" style={{ padding: "0px" }}>
          <div
            className="card"
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body "
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <h4 className="card-title">Payment Gateway Settings</h4>
              <form
                action="gatewaysettings"
                onSubmit={handleSubmit1}
                method="patch"
                encType="multipart/form-data"
                // style={{
                //   backgroundColor: "#0e1b2b",
                //   padding: "20px",
                //   borderRadius: "10px",
                //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                //   // maxWidth: "1100px",
                //   margin: "auto",
                //   color: "#f0f0f0",
                // }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    // gap: "20px",
                  }}
                >
                  {/* RazorPay Settings */}
                  <h4
                    className="card-subtitle"
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#fff",
                      marginBottom: "20px",
                    }}
                  >
                    RazorPay
                  </h4>
                  <div
                    style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                  >
                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>
                        Razorpay Payout
                      </label>
                      <select
                        style={{
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        value={razorPayout}
                        onChange={(e) => setRazorpayout(e.target.value)}
                      >
                        <option value="true">Enable</option>
                        <option value="false">Disable</option>
                      </select>
                    </div>

                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>
                        Razorpay Deposit
                      </label>
                      <select
                        style={{
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        value={razorDeposit}
                        onChange={(e) => setRazorDeposit(e.target.value)}
                      >
                        <option value="true">Enable</option>
                        <option value="false">Disable</option>
                      </select>
                    </div>

                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>RazorPay Auto</label>
                      <select
                        style={{
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        value={razorpayAuto}
                        onChange={(e) => setRazorpayAuto(e.target.value)}
                      >
                        <option value="true">Enable</option>
                        <option value="false">Disable</option>
                      </select>
                    </div>
                  </div>

                  {/* RazorPay Keys */}
                  <div
                    style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                  >
                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>RazorPay Key</label>
                      <input
                        style={{
                          textIndent: "5px",
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={razorPayKey}
                        onChange={(e) => setRazorpayKey(e.target.value)}
                        placeholder="Enter RazorPay Key"
                      />
                    </div>

                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>
                        RazorPay SecretKey
                      </label>
                      <input
                        style={{
                          textIndent: "5px",
                          textIndent: "5px",
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={razorPaySecretKey}
                        onChange={(e) => setRazorpaysecretKey(e.target.value)}
                        placeholder="Enter RazorPay Secret Key"
                      />
                    </div>

                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>Account Name</label>
                      <input
                        style={{
                          textIndent: "5px",
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}
                        placeholder="Enter Account Number"
                      />
                    </div>
                  </div>

                  {/* PhonePe Settings */}
                  <h4
                    className="card-subtitle"
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#fff",
                      margin: "20px 0px",
                    }}
                  >
                    PhonePe
                  </h4>

                  <div
                    style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                  >
                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>Status</label>
                      <select
                        style={{
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        value={PhoneStatus}
                        onChange={(e) => setPhoneStatus(e.target.value)}
                      >
                        <option value="true">Enable</option>
                        <option value="false">Disable</option>
                      </select>
                    </div>

                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>MID</label>
                      <input
                        style={{
                          textIndent: "5px",
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={PhoneMID}
                        onChange={(e) => setPhoneMID(e.target.value)}
                        placeholder="Enter MID"
                      />
                    </div>

                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>KEY</label>
                      <input
                        style={{
                          textIndent: "5px",
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={phonePeKey}
                        onChange={(e) => setPhonePeKey(e.target.value)}
                        placeholder="Enter PhonePe Key"
                      />
                    </div>
                  </div>

                  {/* UPI Gateway */}
                  <h4
                    className="card-subtitle"
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#fff",
                      margin: "20px 0px",
                    }}
                  >
                    UPI Gateway
                  </h4>

                  <div
                    style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                  >
                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>Status</label>
                      <select
                        style={{
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        value={upiGateway}
                        onChange={(e) => setUpiGateway(e.target.value)}
                      >
                        <option value="true">Enable</option>
                        <option value="false">Disable</option>
                      </select>
                    </div>

                    <div style={{ flex: 1 }}>
                      <label style={{ fontSize: "18px" }}>KEY</label>
                      <input
                        style={{
                          textIndent: "5px",
                          width: "100%",
                          height: "40px",
                          marginTop: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={upiGatewayKey}
                        onChange={(e) => setUpiGatewayKey(e.target.value)}
                        placeholder="Enter Upi Gateway Key"
                      />
                    </div>
                  </div>

                  <div style={{ marginTop: "25px", textAlign: "center" }}>
                    <button
                      type="submit"
                      style={{
                        // width: "100%",
                        height: "40px",
                        // padding: "10px 20px",
                        backgroundColor: buttonColor,
                        color: "#fff",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "18px",
                        border: "none",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseEnter={() => setButtonColor("darkgreen")} // on hover, change color
                      onMouseLeave={() => setButtonColor("#34445e")} // reset color when not hovering
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gateway;
