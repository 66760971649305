import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "atropos/css";

import Navbar from "./app/shared/Navbar";
import Sidebar from "./app/shared/Sidebar";
import Footer from "./app/shared/Footer";
import UserCard from "./app/shared/UserCard";
// import Adduser from "./app/components/userManagement/Adduser";
// import Addadmin from "./app/components/adminManagement/Addadmin";

ReactDOM.render(
  <BrowserRouter>
    <App />
    {/* <UserCard cardName={"Test card"} data={120} myColor={"green"} /> */}

    {/* <Navbar />
    <Sidebar /> */}
    {/* <Adduser />
    <Addadmin /> */}
  </BrowserRouter>,
  document.getElementById("root")
);
