import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const Bonushistory = ({ isUserNumberVisible }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState();

  //use for pagination..
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [totals, setTotals] = useState({
    bonusbyadmin: 0,
    joiningbonus: 0,
  });
  let [limit, setLimit] = useState(10);
  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  //user for searching..
  const [searchList, setSearchList] = useState(0);
  const [searchType, setSearchType] = useState(0);

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl + `txn/bonusbyadmin/all?page=${pageNumber}&_limit=${limit}`,
        { headers }
      )
      .then((res) => {
        setUser(res.data.admin);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
        let totalsData = {
          bonusbyadmin: 0,
          joiningbonus: 0,
        };

        res.data.admin.forEach((item) => {
          if (item.status === "Bonus by Admin") {
            totalsData.bonusbyadmin += item.amount;
          } else if (item.status === "Joning bonus by Admin") {
            totalsData.joiningbonus += item.amount;
          }
        });

        setTotals(totalsData);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    // const newDate = date.toLocaleString("default", {
    //   month: "long",
    //   day: "numeric",
    //   hour: "numeric",
    //   hour12: true,
    //   minute: "numeric",
    // });
    // return newDate;
  };

  useEffect(() => {
    profle();
  }, [pageNumber, limit]);

  if (user == undefined) {
    return null;
  }

  const boxStyle = {
    padding: "20px",
    margin: "40px 20px",
    borderRadius: "5px",
    // marginLeft: "15vh",
    color: "#fff",
    fontSize: "20px",
    display: "inline-block",
    // minWidth: "250px",

    textAlign: "center",
  };

  const greenBox = {
    ...boxStyle,
    backgroundColor: "#28a745", // Green
  };

  const orangeBox = {
    ...boxStyle,
    backgroundColor: "#ff8c00", // Orange
  };

  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div
            className="card"
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body text-light"
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <h4
                className="card-title"
                // style={{ color: "white" }}
              >
                Bonus history
              </h4>
              <select
                className="form-control col-sm-1 bg-light text-dark"
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
                style={{
                  // borderRadius: "4px",
                  backgroundColor: "#f8f9fb",
                  color: "black",
                  minWidth: "120px",
                  height: "40px",
                }}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>

              {user.length > 0 && (
                <div
                  style={{
                    textAlign: "center",
                    textWrap: "wrap",
                  }}
                >
                  <div style={greenBox}>
                    Rs.{totals.bonusbyadmin.toFixed(2)} <br /> Bonus By Admin
                  </div>
                  <div style={orangeBox}>
                    Rs.{totals.joiningbonus.toFixed(2)} <br /> Total Joining
                    Bonus
                  </div>
                </div>
              )}
              {user.length > 0 ? (
                <>
                  <div className="table-responsive mt-3">
                    <table
                      className="table text-light table-hover"
                      // style={{ borderRadius: "10px", color: "#fff" }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#14213d" }}>
                          <th>#</th>
                          <th> ID</th>
                          {isUserNumberVisible && <th> phone</th>}
                          <th> User</th>
                          <th> Amount </th>
                          <th> Status </th>
                          <th> Date </th>
                        </tr>
                      </thead>

                      <tbody>
                        {user &&
                          user.map((data, key) => (
                            <tr
                              key={data._id}
                              style={{ borderBottom: "1px solid #e5e7eb" }}
                            >
                              <td>{key + 1}</td>
                              <td>{data._id}</td>
                              {isUserNumberVisible && (
                                <td>{data.userId ? data.userId.phone : ""}</td>
                              )}
                              <td>
                                {data.userId && (
                                  <Link
                                    className="nameButton badge"
                                    to={`/user/view_user/${data.userId._id}`}
                                    // style={{
                                    //   backgroundColor: "#e5e7eb",
                                    //   color: "#1f2937",
                                    //   fontWeight: "bold",
                                    //   borderRadius: "20px",
                                    //   padding: "5px 15px",
                                    //   textTransform: "capitalize",
                                    //   fontSize: "14px",
                                    // }}
                                  >
                                    {data.userId.name}
                                  </Link>
                                )}
                              </td>
                              <td
                                style={{
                                  color: "#3ea113",
                                  fontWeight: "bold",
                                }}
                              >
                                + ₹{data.amount}
                              </td>
                              <td
                                style={{
                                  color: "#3ea113",
                                  fontWeight: "bold",
                                }}
                              >
                                {data.status}
                              </td>
                              <td>{dateFormat(data.createdAt)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#14213d",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginTop: "15px",
                  }}
                >
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
          {numberOfPages > 1 && (
            <div className="mt-4">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                pageLinkStyle={{ color: "#fff" }}
                activeLinkStyle={{
                  backgroundColor: "#00ff00",
                  borderColor: "#00ff00",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Bonushistory;
