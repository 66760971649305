import React from "react";
import { FaUser } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserCard.css"; // Custom CSS for additional styles
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const UserCard = ({ data, cardName, myColor, myIcon, forwardUrl }) => {
  return (
    <div
      className="card-container"
      style={{ boxShadow: `1px 1px 4px ${myColor}` }}
    >
      <div
        className="card-container-body"
        // style={{ border: `2px solid ${myColor}` }}
      >
        {/* click button div at top right */}
        <div className="card-click-button">
          {forwardUrl && (
            <Link to={forwardUrl ? forwardUrl : "/"}>
              <span
                className="card-click-button-text"
                style={{ color: `${myColor}`, border: `1px solid ${myColor}` }}
              >
                View All
              </span>
            </Link>
          )}
        </div>

        <div className="card-info-div">
          <div
            className="card-info-logo"
            style={{
              color: `${myColor}`,
              border: `1px solid ${myColor}`,
              // boxShadow: ` 0px 0px 4px ${myColor}`,
            }}
          >
            <i className={myIcon}></i>
          </div>
          <div className="card-info-details">
            <span>{data}</span>
            <span>{cardName} </span>
          </div>
        </div>

        <div className="card-bottom-background" style={{ color: `${myColor}` }}>
          <i className={myIcon}></i>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
