import axios from "axios";
import "../components/common.css";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const $ = require("jquery");
$.Datatable = require("datatables.net");

function Earings() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    var baseUrl = beckendLiveApiUrl;
  }

  //use for pagination..
  let [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };

  const [EARING, setEARING] = useState();
  const [TOTELEARING, setTOTELEARING] = useState(0);
  const [startDate, setStartDate] = useState(
    new DateObject().subtract(4, "days")
  );
  const [endDate, setEndDate] = useState(new DateObject().add(4, "days"));

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  useEffect(() => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `admin/Earning?page=${pageNumber}&_limit=${limit}&FROM_DATE=${startDate}&TO_DATE=${endDate}`,
        { headers }
      )
      .then((res) => {
        // console.log("Earning DATA===", res.data.admin);
        setEARING(res.data.admin);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
        let totalEarn = 0;
        res.data.admin &&
          res.data.admin.forEach((earning) => {
            totalEarn += earning.amount;
          });
        // setTOTELEARING(totalEarn);
      });

    axios.get(baseUrl + `admin/Earning/total`, { headers }).then((res) => {
      console.log("Total Earning", res.data.total.total);
      setTOTELEARING(res.data.total.total);
    });
  }, [pageNumber, limit, startDate, endDate]);

  const createDownloadData = () => {
    handleExport();
  };
  const handleExport = () => {
    let table1 = [
      {
        A: "id",
        B: "Amount",
        // F:"Bonus by (ID)"
      },
    ];

    EARING?.forEach((row) => {
      const Earing = row;
      //console.log("exldata", Earing);
      table1.push({
        A: `Earn From Challange ${Earing.earnedForm} on ${Earing.createdAt}`,
        B: Earing.amount,
        // F:(userDetails.actionBy)?userDetails.actionBy:'N/A'
      });
    });

    //table1 = [{A:"User Details"}].concat(table1);
    //const finalData = [...table1];
    //console.log(finalData);
    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "AdminEarning.xlsx");
  };

  const downloadPDF = () => {
    // Prepare the table data
    let tableData = EARING?.map((row) => ({
      A: row.createdAt,
      B: row.earnedForm,
      C: row.amount.toString(),
    }));

    // Generate the PDF
    const doc = new jsPDF();
    const columns = [
      { header: "date", dataKey: "A" },
      { header: "Earn From Challenge", dataKey: "B" },
      { header: "Amount", dataKey: "C" },
    ];

    // Use autoTable to render the table
    doc.autoTable({
      head: [columns.map((col) => col.header)], // Set table headers
      body: tableData.map((row) => [row.A, row.B, row.C]),
      startY: 20, // Start rendering below the initial headers
    });

    // Save the generated PDF
    doc.save("AdminEarnings.pdf");
  };

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div
            className="card text-light"
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body position-relative"
              // style={{ border: "2px solid green" }}
            >
              <h4 className="card-title mt-4">Admin Earning</h4>
              {/* download data buttons */}
              <div className="download-button">
                <button
                  onClick={() => {
                    createDownloadData();
                  }}
                  className="btn btn-primary "
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#0056b3";
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#007bff";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  <RiFileExcel2Line style={{ fontSize: "24px" }} />
                </button>

                {/* pdf download button */}
                <button
                  onClick={() => {
                    downloadPDF();
                  }}
                  className="btn btn-danger"
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#841f29";
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#dc3545";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  <FaRegFilePdf style={{ fontSize: "24px" }} />
                </button>
              </div>

              <div
                className="d-flex flex-wrap justify-content-start justify-content-sm-between  align-items-center mt-3 mb-3 w-100"
                // style={{ border: "2px solid red" }}
              >
                {/* Start Date Picker */}
                <div
                  style={{
                    marginRight: "10px",
                    // border: "2px solid green",
                  }}
                  className="commoncss-custom-with-datediv"
                >
                  <label
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}
                  >
                    Start Date:
                  </label>
                  <DatePicker
                    value={startDate}
                    onChange={setStartDate}
                    style={{
                      backgroundColor: "#f8f9fb",
                      color: "#black",
                      borderRadius: "5px",
                      padding: "5px",
                      minWidth: "120px",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>

                {/* End Date Picker */}
                <div
                  style={{
                    marginRight: "10px",
                    // border: "2px solid green",
                  }}
                  className="commoncss-custom-with-datediv"
                >
                  <label
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}
                  >
                    End Date:
                  </label>
                  <DatePicker
                    value={endDate}
                    onChange={setEndDate}
                    style={{
                      backgroundColor: "#f8f9fb",
                      color: "#black",
                      borderRadius: "5px",
                      padding: "5px",
                      minWidth: "120px",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>

                <select
                  // className="form-control col-sm-1 bg-light text-dark"
                  className="sm:mt-4 commoncss-custom-with-datediv"
                  id="pagelimit"
                  name="pagelimit"
                  onChange={setpageLimit}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#f8f9fb",
                    color: "black",
                    minWidth: "120px",
                    height: "40px",
                    marginBottom: "4px",
                    // border: "2px solid green",
                    // marginTop: "4px",
                  }}
                >
                  <option value="10">Set limit</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
              {EARING && EARING.length > 0 ? (
                <>
                  <div className="table-responsive mt-3">
                    <div
                      style={{
                        backgroundColor: "#202938",
                        padding: "10px",
                        margin: "18px",
                        borderRadius: "5px",
                        color: "#fff",
                        fontSize: "20px",
                        textAlign: "center",
                        // width: "30%",
                      }}
                    >
                      Admin Total Earning: &#8377; {TOTELEARING.toFixed(2)}
                    </div>
                    <table
                      className="table text-light table-hover"
                      style={{ borderRadius: "10px", color: "#fff" }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#14213d" }}>
                          <th>#</th>
                          <th>ID</th>
                          <th>Amount</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {EARING &&
                          EARING.map((Earing, index) => (
                            <tr
                              key={index}
                              style={{ borderBottom: "1px solid #e5e7eb" }}
                            >
                              <td>{index + 1}</td>
                              <td>
                                <div className="d-flex">
                                  <span className="pl-2">
                                    Earn From Challange
                                    <span className="pl-2 text-info">
                                      {Earing.earnedForm}
                                    </span>
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="">₹{Earing.amount}</div>
                              </td>
                              <td>{dateFormat(Earing.createdAt)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#14213d",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                  }}
                >
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
          {numberOfPages > 1 && (
            <div className="mt-4">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                pageLinkStyle={{ color: "green" }}
                activeLinkStyle={{
                  backgroundColor: "green",
                  borderColor: "green",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Earings;
