import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

const Withdrawterminate = () => {
 
  const isMounted = useRef(true);
  const [socketWithdrow, setSocketwithdrow] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const websocketURL = process.env.REACT_APP_SOCKET_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

    function  disableUpdate() {
        axios.put(baseUrl+ "updatesettings",{
            withdrawOutput: false
        })
        .then(response => {
            console.log('Update successful', response);
            socketWithdrow.emit("withDrowDisable");
        })
        .catch(error => {
            console.error('Update failed', error);
        });
    }

    function  enableUpdate() {
        axios.put(baseUrl+ "updatesettings",{
            withdrawOutput: true
        })
        .then(response => {
            console.log('Update successful', response);
            socketWithdrow.emit("withDrowDisable");
        })
        .catch(error => {
            console.error('Update failed', error);
        });
    }

    

    useEffect(() => {
      WebSocket.prototype.emit = function (event, data) {
        if (this.readyState === WebSocket.OPEN)
          this.send(JSON.stringify({ event, data }));
      };
      WebSocket.prototype.listen = function (eventName, callback) {
        this._socketListeners = this._socketListeners || {};
        this._socketListeners[eventName] = callback;
      };
  
      let socket = new WebSocket(websocketURL);
  
      //let socket = new WebSocket("ws://192.168.29.119:5001/server");
      function openFunc() {
        socket.onopen = () => {
          console.log("websocket is connected 👍");
          setSocketwithdrow(socket);
          socket.pingTimeout = setTimeout(() => {
            socket.close();
            setSocketwithdrow(undefined);
          }, 30000 + 1000);
        };
      }
  
      function listenFunc() {
        socket.onmessage = function (e) {
          try {
            const { event, data } = JSON.parse(e.data);
            socket._socketListeners[event](data);
          } catch (error) {
            console.log(error);
          }
        };
  
        socket.listen("ping", (data) => {
          socket.emit("pong", 2);
          clearTimeout(socket.pingTimeout);
          socket.pingTimeout = setTimeout(() => {
            console.log("ping terminate works 🏩");
            socket.close();
            setSocketwithdrow(undefined);
          }, 30000 + 1000);
        });

      }

      function closeFunc() {
        socket.onclose = () => {
          console.log("socket disconnected wow 😡");
  
          if (isMounted.current) {
            clearTimeout(socket.pingTimeout);
            setSocketwithdrow(undefined);
  
            let socket = new WebSocket(websocketURL);
  
            //socket = new WebSocket("ws://192.168.29.119:5001/server");
            openFunc();
            listenFunc();
            closeFunc();
          }
        };
      }

      openFunc();
      listenFunc();
      closeFunc();
  
      return () => {
        isMounted.current = false;
        clearTimeout(socket.pingTimeout);
        setSocketwithdrow(undefined);
        socket.close();
      };
    }, []);

  return (
    <>
      <div
        className="w-100 bg-white p-2 shadow h-50 "
        style={{ border: "1px solid #ccc" }}
      >
        <ul
          className="nav nav-pills mb-4 justify-content-between  "
          id="pills-tab"
          role="tablist"
        >
          <li
            className="nav-item"
            role="presentation"
            style={{ border: "1px solid #ccc", borderRadius: "5px" }}
          >
            <button
              className="nav-link active btn btn-success "
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={enableUpdate}
            >
              Withdraw Enable
            </button>
          </li>
          <li
            className="nav-item"
            role="presentation"
            style={{ border: "1px solid #ccc", borderRadius: "5px" }}
          >
            <button
              className="nav-link active btn btn-danger"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={disableUpdate}
            >
              Withdraw Disable
            </button>
          </li>
        </ul>

        <div className="tab-content " id="pills-tabContent" style={{marginTop:"70px"}}>
          <div
            className="tab-pane fade show active "
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabindex="0"
            style={{ color: "green", textAlign:"center" }}
          >
            <span style={{ fontWeight: "bold" }}>Withdraw Status :Withdrawal is
            Active right now !</span> 
          </div>
          <div
            className="tab-pane fade  "
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
            style={{ color: "red",marginLeft:"60vh" }}
          >
            <span style={{ fontWeight: "bold" }}>Withdraw Status :Withdrawal is
            Disabled !</span> <br/>
            <span style={{color:"orange", fontSize:"18px"}}> Click on Withdraw Active button to active the withdraw section !</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdrawterminate;
