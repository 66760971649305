import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import axios from "axios";
import "./Navbar.css";
import adminImg from "../../assets/images/faces/face12.jpg";

class r extends Component {
  logout = () => {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
    }

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        console.log(res);
        // setUser(res.data)
        localStorage.removeItem("token", "user");
        window.location.reload();
        window.location.assign("/adminlogin");
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token", "user");
          localStorage.removeItem("token", "user");
          window.location.assign("/adminlogin");
        }
      });
  };

  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  render() {
    console.log("Passed props to the navbar component is :-", this.props);
    return (
      // <nav className="r p-0 fixed-topw -100   d-flex flex-row">
      <nav className="r navTest">
        {/* <div className="r-brand-wrapper d-flex d-lg-none align-items-center justify-content-center testClass">
          <Link className="r-brand brand-logo-mini" to="/">
            <img
              src="https://cdn-icons-png.flaticon.com/512/2206/2206368.png"
              alt="logo"
            />
          </Link>
        </div> */}
        <div
          className="r-menu-wrapper flex-grow d-flex   nav-bar-container px-1"
          // style={{ border: "2px solid red" }}
        >
          <button
            // style={{ border: "2px solid yellow" }}
            className="r-toggler align-self-center  d-none d-lg-block toggleBtn"
            type="button"
            onClick={() => {
              document.body.classList.toggle("sidebar-icon-only");
            }}
          >
            <span className="mdi mdi-menu"></span>
          </button>

          <button
            // style={{ border: "2px solid green" }}
            className="r-toggler d-lg-none align-self-center toggleBtn"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-format-line-spacing"></span>
          </button>

          {/* <ul className="r-nav d-flex align w-100 testClass1">
            <li className="nav-item w-100">
              <h6>{this.props.userAname}</h6>
            </li>
          </ul> */}

          <ul className=" profile-info-container pt-2">
            <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle
                as="a"
                className="nav-link  cursor-pointer no-caret"
              >
                <div className="r-profile d-flex nav-bar-admin-profile-container">
                  {/* <img
                    width="20px"
                    height="20px"
                    className="img-xs rounded-circle"
                    src="https://cdn-icons-png.flaticon.com/512/2206/2206368.png"
                    alt="profile"
                  /> */}
                  <img
                    style={{ border: "2px solid white" }}
                    className="rounded-circle"
                    src={adminImg}
                    width="40px"
                    heigh="40px"
                  />

                  <p className="mb-0 d-none d-sm-block r-profile-name">
                    <Trans>User: {this.props.usertype}</Trans>
                  </p>
                  <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="r-dropdown preview-list r-profile-dropdown-menu overflow-auto"
                style={{
                  maxHeight: "calc(100vh - 160px)",
                }}
              >
                <Dropdown.Item className="preview-item">
                  <Link to={"/admin/profile"} className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-account-circle text-info"></i>
                    </div>
                  </Link>
                  <Link to={"/admin/profile"} className="preview-item-content">
                    <p className="preview-subject mb-1 text-dark">
                      <Trans>Profile</Trans>
                    </p>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="preview-item">
                  <Link to={"/admin/update"} className="preview-thumbnail">
                    <div className="preview-icon rounded-circle">
                      <i className="mdi mdi-update text-primary"></i>
                    </div>
                  </Link>
                  <Link to={"/admin/update"} className="preview-item-content">
                    <p className="preview-subject">
                      <Trans>Update Password</Trans>
                    </p>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />

                {/* message box */}

                <Dropdown.Item className="preview-item">
                  <Link to={"/message-box"} className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-message-text text-secondary"></i>
                    </div>
                  </Link>
                  <Link to={"/message-box"} className="preview-item-content">
                    <p className="preview-subject mb-1 text-dark">
                      <Trans>Message Box</Trans>
                    </p>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />

                {/* message box 2 (marquee) */}

                <Dropdown.Item className="preview-item">
                  <Link to={"/message-box2"} className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-comment-processing text-primary"></i>
                    </div>
                  </Link>
                  <Link to={"/message-box2"} className="preview-item-content">
                    <p className="preview-subject mb-1 text-dark">
                      <Trans>Marquee-text Box</Trans>
                    </p>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />

                {/* room code message box */}
                <Dropdown.Item className="preview-item">
                  <Link to={"/message-box3"} className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-message-text text-secondary"></i>
                    </div>
                  </Link>
                  <Link to={"/message-box3"} className="preview-item-content">
                    <p className="preview-subject mb-1 text-dark">
                      <Trans>BattlePage-Message</Trans>
                    </p>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />

                {/* SLIDER IMAGES */}
                <Dropdown.Item className="preview-item">
                  <Link to={"/slider-images"} className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-comment-processing text-info"></i>
                    </div>
                  </Link>
                  <Link to={"/slider-images"} className="preview-item-content">
                    <p className="preview-subject mb-1 text-dark">
                      <Trans>Slider ImgMsg</Trans>
                    </p>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />

                {/* Site live or terminate */}

                <Dropdown.Item className="preview-item">
                  <Link to={"/game-terminate"} className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className=" mdi mdi-dice-6 text-danger"></i>
                    </div>
                  </Link>
                  <Link to={"/game-terminate"} className="preview-item-content">
                    <p className="preview-subject mb-1 text-dark">
                      <Trans>Game Terminate</Trans>
                    </p>
                  </Link>
                </Dropdown.Item>

                <Dropdown.Divider />

                {/* Withdrawal terminate */}

                <Dropdown.Item className="preview-item">
                  <Link
                    to={"/withdraw-terminate"}
                    className="preview-thumbnail"
                  >
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className=" mdi mdi-cash-usd text-warning"></i>
                    </div>
                  </Link>
                  <Link
                    to={"/withdraw-terminate"}
                    className="preview-item-content"
                  >
                    <p className="preview-subject mb-1 text-dark">
                      <Trans>Withdraw Disable</Trans>
                    </p>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => this.logout()}
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon rounded-circle">
                      <i className="mdi mdi-logout text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject">
                      <Trans>Log Out</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          {/* <button
            className="r-toggler r-toggler-right d-lg-none align-self-center testClass1"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-format-line-spacing"></span>
          </button> */}
        </div>
      </nav>
    );
  }
}

export default r;
