import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const SucWithdraw = ({ isUserNumberVisible }) => {
  const [user, setUser] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  //user for searching..
  const [searchList, setSearchList] = useState(0);
  const [searchType, setSearchType] = useState(0);
  const [findByStatus, setFindByStatus] = useState(0);
  const [totals, setTotals] = useState({
    success: 0,
    pending: 0,
    failed: 0,
    rejected: 0,
  });

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  //   searching handler
  const searchHandler = (event) => {
    let key = event.target.value;
    if (searchType === "phone" && !Number(key) && key.length >= 1) {
      alert("Please enter a valid phone number");
      return;
    }
    setSearchList(key);
    setFindByStatus(0);
    document.getElementById("findByStatus").value = "0";
  };
  //   search by status handler
  const searchByStatus = (event) => {
    let key = event.target.value;
    setFindByStatus(key);
    setSearchList(0);
    setSearchType(0);
    let selectType = document.getElementById("searchType");
    selectType.value = "0";
  };

  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `txn/withdraw/all?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}&_status=${findByStatus}`,
        { headers }
      )
      .then((res) => {
        console.log("Api data is ;- ", res.data);

        if (
          res.data.message &&
          searchType === "phone" &&
          searchList.length <= 10
        ) {
          return;
        } else if (!res.data.data) {
          setUser(0);
          return;
        }
        console.log("response data is :- ", res.data.data);
        setUser(res.data.data);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
        console.log("wurdhdieyfh", res.data.data);
        let totalsData = {
          success: 0,
          pending: 0,
          failed: 0,
          rejected: 0,
        };

        res?.data?.data?.forEach((item) => {
          if (item.status === "SUCCESS") {
            totalsData.success += item.amount;
          } else if (item.status.toLowerCase() === "pending") {
            totalsData.pending += item.amount;
          } else if (item.status === "FAILED") {
            totalsData.failed += item.amount;
          } else if (item.status === "reject") {
            totalsData.rejected += item.amount;
          }
        });

        setTotals(totalsData);
      });
  };

  const universalCheckPayout = (payment_gatway, txn_id, referenceId) => {
    //alert(payment_gatway);
    if (!referenceId) {
      alert("Payout txn id not found");
    }
    if (payment_gatway === "razorpay") {
      checkrazorpaypayout(txn_id, referenceId);
    } else if (payment_gatway === "decentro") {
      checkpayout(txn_id, referenceId);
    } else if (payment_gatway === "pinelab") {
      //checkPinelabpayout(txn_id, referenceId)
    } else {
      alert("Nothing found");
    }
  };

  const checkpayout = (txn_id, referenceId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `decentropayout/response`,
        { txn_id, referenceId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "SUCCESS" ? "success" : "danger";
        const title =
          res.data.status === "SUCCESS"
            ? "Withdraw successfully"
            : "Transaction Proccessing or Failed";

        profle();
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: "OK",
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const checkrazorpaypayout = (txn_id, referenceId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `razorpaypayoutscheck/response`,
        { txn_id, referenceId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "SUCCESS" ? "success" : "danger";
        const title =
          res.data.status === "SUCCESS"
            ? "Withdraw successfully"
            : "Transaction Proccessing or Failed";

        profle();
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: "OK",
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  let currentTime = Date.now();

  useEffect(() => {
    profle();
  }, [pageNumber, limit, searchList, searchType, findByStatus]);

  if (user === undefined) {
    return null;
  }
  const boxStyle = {
    padding: "20px",
    margin: "40px 20px",
    borderRadius: "5px",
    color: "#fff",
    fontSize: "20px",
    display: "inline-block",
    minWidth: "250px",
    textAlign: "center",
  };

  const greenBox = {
    ...boxStyle,
    backgroundColor: "#28a745", // Green
  };

  const orangeBox = {
    ...boxStyle,
    backgroundColor: "#ff8c00", // Orange
  };

  const redBox = {
    ...boxStyle,
    backgroundColor: "#dc3545", // Red
  };
  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div
            className="card"
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body text-light"
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <h4
                className="card-title"
                // style={{ color: "white" }}
              >
                Withdrawal History
              </h4>
              {/* searching */}
              <div
                className="row d-flex  justify-content-center justify-content-sm-between align-items-center mb-4"
                style={{
                  gap: "10px",

                  // border: "2px solid green",
                  padding: "0px 14px",
                }}
              >
                <select
                  className="form-control col-sm-3 bg-light text-dark commoncss-custom-class-width"
                  id="searchType"
                  name="searchtype"
                  onChange={(e) => {
                    setSearchType(e.target.value);
                    setSearchList(0);
                    document.getElementById("findByStatus").value = "0";
                  }}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#343a40",
                    color: "#fff",
                    border: "1px solid #fff",
                    // marginLeft: "10px",
                    height: "40px",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                >
                  <option value="0">Select Search by</option>
                  <option value="name">Name</option>
                  <option value="phone">Phone</option>
                </select>
                <input
                  type="text"
                  placeholder="Search Here"
                  className=" col-sm-4 bg-light text-dark commoncss-custom-class-width"
                  onChange={searchHandler}
                  value={searchList == 0 ? "" : searchList}
                  style={{
                    fontSize: "22px !important",
                    borderRadius: "5px",
                    border: "1px solid #fff",
                    // marginLeft: "10px",
                    height: "40px",
                    fontSize: "16px !important",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                />
                <h6 className="d-none d-sm-block" style={{ color: "#fff" }}>
                  Or
                </h6>
                <select
                  className="form-control col-sm-3 bg-light text-dark commoncss-custom-class-width"
                  id="findByStatus"
                  name="findByStatus"
                  onChange={searchByStatus}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#343a40",
                    color: "#fff",
                    border: "1px solid #fff",
                    // marginLeft: "10px",
                    height: "40px",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                >
                  <option value="0">Search Status</option>
                  <option value="SUCCESS">Success</option>
                  <option value="Pending">Pending</option>
                  <option value="pending">pending</option>
                  <option value="none">Proccessing</option>
                  <option value="reject">Reject</option>
                  <option value="FAILED">Failed</option>
                </select>
                <select
                  className="form-control col-sm-1 bg-light text-dark commoncss-custom-class-width"
                  id="pagelimit"
                  name="pagelimit"
                  onChange={setpageLimit}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#343a40",
                    color: "#fff",
                    height: "40px",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                >
                  <option value="10">Set limit</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
              {user.length > 0 && (
                <div
                  style={{
                    textAlign: "center",
                    textWrap: "wrap",
                  }}
                >
                  <div style={greenBox}>
                    Rs.{totals.success.toFixed(2)} <br /> Success Withdrawals
                  </div>
                  {/* <div style={orangeBox}>
                  Rs.{totals.pending.toFixed(2)} <br /> Pending Withdrawals
                </div> */}
                  <div style={redBox}>
                    Rs.{totals.failed.toFixed(2)} <br /> Failed Withdrawals
                  </div>
                  <div style={redBox}>
                    Rs.{totals.rejected.toFixed(2)} <br /> Rejected Withdrawals
                  </div>
                </div>
              )}
              {user.length > 0 ? (
                <>
                  <div className="table-responsive mt-3">
                    <table
                      className="table text-light table-hover"
                      // style={{ borderRadius: "10px", color: "#fff" }}
                    >
                      <thead>
                        <tr
                        // style={{ backgroundColor: "#14213d" }}
                        >
                          <th>#</th>
                          <th> ID</th>
                          {isUserNumberVisible && <th> phone</th>}
                          <th> User</th>
                          <th> type</th>
                          <th> Holder name</th>
                          <th> Account Number</th>
                          <th> IFSC Code </th>
                          <th> Amount </th>
                          <th> Status </th>
                          <th> Date </th>
                        </tr>
                      </thead>

                      <tbody>
                        {user &&
                          user.map((data, key) => {
                            let currentTime = Date.now();
                            let gameCreatedAt = new Date(
                              data.createdAt
                            ).getTime();
                            return (
                              <tr
                                key={data._id}
                                style={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <td>{key + 1}</td>
                                <td>{data._id}</td>
                                {isUserNumberVisible && (
                                  <td>
                                    {data.userId ? data.userId.phone : ""}
                                  </td>
                                )}
                                <td>
                                  {data.userId && (
                                    <Link
                                      className="nameButton badge"
                                      to={`/user/view_user/${data.userId._id}`}
                                      // style={{
                                      //   backgroundColor: "#e5e7eb",
                                      //   color: "#1f2937",
                                      //   fontWeight: "bold",
                                      //   borderRadius: "20px",
                                      //   padding: "5px 15px",
                                      //   textTransform: "capitalize",
                                      //   fontSize: "14px",
                                      // }}
                                    >
                                      {data.userId.name}
                                    </Link>
                                  )}
                                </td>
                                <td>{data.withdrawType}</td>
                                <td>{data.accountHolderName || ""}</td>
                                <td>{data.accountNumber || ""}</td>
                                <td>{data.ifscCode || ""}</td>
                                <td
                                  style={{
                                    color:
                                      data.status === "FAILED" ||
                                      data.status === "reject"
                                        ? "#ff0000"
                                        : data.status === "Pending"
                                        ? "#aaad11"
                                        : "#3ea113",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ₹{data.amount}
                                  {data.withdrawCharge > 0 && (
                                    <div className="text-light fw-normal small">
                                      Withdraw charge: &#x20B9;{" "}
                                      {data.withdrawCharge}
                                    </div>
                                  )}
                                </td>
                                <td
                                  style={{
                                    color:
                                      data.status === "FAILED" ||
                                      data.status === "reject"
                                        ? "#ff0000"
                                        : data.status === "Pending"
                                        ? "#aaad11"
                                        : "#3ea113",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data.status === "Refunded"
                                    ? "Withdraw Credited Back to Wallet"
                                    : data.status === "none"
                                    ? "Processing"
                                    : data.status}
                                </td>
                                {/* <td>
                              {
                                (data.status != 'SUCCESS' && data.status != 'FAILED' && (parseInt(gameCreatedAt) + 7200000) < currentTime) ? <button className="btn btn-danger" onClick={() => universalCheckPayout(data.payment_gatway, data._id, data.referenceId)} >Check {data.payment_gatway}</button> : 'Checked All or Wait'
                              }
                            </td> */}
                                <td>{dateFormat(data.createdAt)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#14213d",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginTop: "15px",
                  }}
                >
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
          {numberOfPages > 1 && (
            <div className="mt-4">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                pageLinkStyle={{ color: "#fff" }}
                activeLinkStyle={{
                  backgroundColor: "#00ff00",
                  borderColor: "#00ff00",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default SucWithdraw;
