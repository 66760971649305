import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const $ = require("jquery");
$.Datatable = require("datatables.net");

export default function New() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [challenge, setchallenge] = useState();

  const Allchallenge = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `challange/new_challange`, { headers }).then((res) => {
      if (res.data.message == "Game challenges not found") {
        setchallenge([]);
        return;
      }
      console.log(res);
      setchallenge(res.data);
      $("table").dataTable();
    });
  };

  const CancelGame = async (id) => {
    const confirm = window.confirm("are you sure to cancel");

    if (confirm == true) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      axios
        .patch(
          baseUrl + `challange/Cancel/${id}`,
          { Cancelled_by: access_token },
          { headers }
        )
        .then((res) => {
          Allchallenge();
        });
    } else {
      window.alert("sorry try again");
    }
  };
  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  useEffect(() => {
    Allchallenge();
  }, []);

  if (challenge == undefined) {
    return null;
  }

  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div
            className="card"
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body text-light"
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <h4
                className="card-title"
                // style={{ color: "white" }}
              >
                New Challanges
              </h4>
              {challenge.length > 0 ? (
                <div className="table-responsive mt-3">
                  <table
                    className="table text-light table-hover"
                    // style={{ borderRadius: "10px", color: "#fff" }}
                  >
                    <thead>
                      <tr
                      // style={{ backgroundColor: "#14213d" }}
                      >
                        <th>#</th>
                        <th> ID</th>
                        <th> Creator</th>
                        <th> Amount </th>
                        <th> Status </th>
                        <th> Game Type </th>
                        <th>Date</th>
                        <th> Action </th>
                      </tr>
                    </thead>

                    <tbody>
                      {challenge ? (
                        challenge.map((game, key) => (
                          <tr
                            key={game._id}
                            style={{ borderBottom: "1px solid #e5e7eb" }}
                          >
                            <td>{key + 1}</td>
                            <td>{game._id}</td>
                            <td>
                              {game.createdBy && (
                                <Link
                                  className="nameButton badge"
                                  to={`/user/view_user/${game.createdBy._id}`}
                                  // style={{
                                  //   backgroundColor: "#e5e7eb",
                                  //   color: "#1f2937",
                                  //   fontWeight: "bold",
                                  //   borderRadius: "20px",
                                  //   padding: "5px 15px",
                                  //   textTransform: "capitalize",
                                  //   fontSize: "14px",
                                  // }}
                                >
                                  {game.createdBy.name}
                                </Link>
                              )}
                            </td>
                            {/* <td>
                            {game.createdBy.name}
                          </td> */}

                            <td>₹{game.gameAmount}</td>
                            <td className="text-primary font-weight-bold">
                              {game.status}
                            </td>
                            <td
                              style={{ fontSize: "13px", fontWeight: "bold" }}
                            >
                              {" "}
                              {decodeURIComponent(game.gameType)}
                            </td>
                            <td>{dateFormat(game.createdAt)} </td>
                            <td>
                              <Link
                                type="button"
                                className="btn btn-primary mx-1"
                                to={`/view/${game._id}`}
                                style={{
                                  fontSize: "20px",
                                  borderRadius: "10px",
                                }}
                              >
                                View
                              </Link>
                              {/* {game.status != "cancelled" && game.status != "completed" && game.status != "conflict" && <button type='button' className="btn  mx-1 btn-danger" onClick={() => CancelGame(game._id)}>Cancel</button>} */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>New battle not created yet</div>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#14213d",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginTop: "15px",
                  }}
                >
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
