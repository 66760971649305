import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import "./Roomcode.css";

const $ = require("jquery");
$.Datatable = require("datatables.net");

function Roomcode() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    var baseUrl = beckendLiveApiUrl;
  }

  //use for pagination..
  let [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };

  const [EARING, setEARING] = useState();
  const [TOTELEARING, setTOTELEARING] = useState();

  const [values, setValues] = useState([
    new DateObject().subtract(4, "days"),
    new DateObject().add(4, "days"),
  ]);

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  useEffect(() => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl +
          `admin/Earning?page=${pageNumber}&_limit=${limit}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`,
        { headers }
      )
      .then((res) => {
        console.log("earning only :- ", res);
        setEARING(res.data.admin);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
      });
    axios.get(baseUrl + `admin/Earning/total`, { headers }).then((res) => {
      console.log("total earning", res.data.total.total);
      setTOTELEARING(res.data.total.total);
    });
  }, [pageNumber, limit, values]);

  const createDownloadData = () => {
    handleExport();
  };
  const handleExport = () => {
    let table1 = [
      {
        A: "id",
        B: "Amount",
        // F:"Bonus by (ID)"
      },
    ];

    EARING.forEach((row) => {
      const Earing = row;
      //console.log("exldata", Earing);
      table1.push({
        A: `Earn From Challange ${Earing.Earned_Form} on ${Earing.createdAt}`,
        B: Earing.Ammount,
        // F:(userDetails.actionBy)?userDetails.actionBy:'N/A'
      });
    });

    //table1 = [{A:"User Details"}].concat(table1);
    //const finalData = [...table1];
    //console.log(finalData);
    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "AdminEarning.xlsx");
  };

  const [roomCodeValue, setRoomCodeValue] = useState();
  const [roomCodeResult, serRoomCodeResult] = useState(null);

  function fetchRoomCodeResutl(roomCode) {
    try {
      var token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(baseUrl + `admin/challange/game/validate?roomCode=${roomCode}`, {
          headers: headers, // Fixed headers assignment
        })
        .then((res) => {
          console.log("Room code serach result :- ", res);
          console.log(res.data);
          serRoomCodeResult(res.data); // Assuming setAll is correct function name
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error or throw it if necessary
        });
    } catch (error) {
      console.error("Error in API request:", error);
      // Handle error or throw it if necessary
    }
  }

  return (
    <>
      <div className="d-flex justify-content-center mb-5">
        <form className="d-flex roomcode-form">
          <input
            type="text"
            className="form-control"
            placeholder="Enter room code"
            onChange={(e) => setRoomCodeValue(e.target.value)}
            name="search"
          />
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => fetchRoomCodeResutl(roomCodeValue)}
          >
            <i className="fa fa-search" />
          </button>
        </form>
      </div>

      <div className="roomcode-data-container">
        {roomCodeResult ? (
          <>
            <div className="roomcode-creator-data">
              <h2 className={`snip-h2 mb-4`}>
                Creator{" "}
                {roomCodeResult ? (
                  // <span>{roomCodeResult.creator_name}</span>
                  <span>{"Test user"}</span>
                ) : (
                  <></>
                )}
              </h2>
              <div>
                {/* <p>Status: {roomCodeResult.status}</p>
                <p>Room Code: {roomCodeResult.roomcode}</p>
                <p>Creator ID: {roomCodeResult.creator_id}</p>
                <p>Creator Name: {roomCodeResult.creator_name}</p>
                <p>Player 1 ID: {roomCodeResult.player1_id || "N/A"}</p>
                <p>Player 1 Name: {roomCodeResult.player1_name || "N/A"}</p>
                <p>Player 1 Status: {roomCodeResult.player1_status || "N/A"}</p> */}

                <p>
                  <span>Status: </span>
                  <span> {roomCodeResult.status}</span>
                </p>
                <p>
                  <span>Room Code:</span>
                  <span>{roomCodeResult.roomcode}</span>
                </p>
                <p>
                  {" "}
                  <span>Creator ID:</span>
                  <span>{roomCodeResult.creator_id}</span>
                </p>
                <p>
                  {" "}
                  <span>Creator Name:</span>
                  <span>{roomCodeResult.creator_name}</span>
                </p>
                <p>
                  <span>Player 1 ID:</span>
                  <span>{roomCodeResult.player1_id || "N/A"}</span>
                </p>
                <p>
                  <span>Player 1 Name:</span>
                  <span> {roomCodeResult.player1_name || "N/A"}</span>
                </p>
                <p>
                  <span>Player 1 Status:</span>
                  <span>{roomCodeResult.player1_status || "N/A"}</span>
                </p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {roomCodeResult ? (
          <>
            <div className="roomcode-acceptor-data">
              <h2 className={`snip-h2 mb-4`}>
                Acceptor{" "}
                {roomCodeResult ? (
                  // <span>{roomCodeResult.creator_name}</span>
                  <span>{"Test "}</span>
                ) : (
                  <></>
                )}
              </h2>
              <div className="">
                {/* <p>Status: {roomCodeResult.status}</p>
                <p>Room Code: {roomCodeResult.roomcode}</p>
                <p>Creator ID: {roomCodeResult.creator_id}</p>
                <p>Creator Name: {roomCodeResult.creator_name}</p>
                <p>Player 2 ID: {roomCodeResult.player2_id || "N/A"}</p>
                <p>Player 2 Name: {roomCodeResult.player2_name || "N/A"}</p>
                <p>Player 2 Status: {roomCodeResult.player2_status || "N/A"}</p> */}

                <p>
                  <span>Status:</span>
                  <span>{roomCodeResult.status}</span>
                </p>
                <p>
                  <span>Room Code:</span>
                  <span>{roomCodeResult.roomcode}</span>
                </p>
                <p>
                  <span>Creator ID:</span>
                  <span>{roomCodeResult.creator_id}</span>
                </p>
                <p>
                  <span>Creator Name:</span>
                  <span>{roomCodeResult.creator_name}</span>
                </p>
                <p>
                  <span>Player 2 ID:</span>
                  <span>{roomCodeResult.player2_id || "N/A"}</span>
                </p>
                <p>
                  <span>Player 2 Name:</span>
                  <span>{roomCodeResult.player2_name || "N/A"}</span>
                </p>
                <p>
                  <span>Player 2 Status:</span>
                  <span>{roomCodeResult.player2_status || "N/A"}</span>
                </p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Roomcode;
