import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaRegCircleUser } from "react-icons/fa6";
import "./Profile.css";

export default function UpdatePassword() {
  const history = useHistory();

  const [Password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setCPassword] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const addAdmin = async (e) => {
    e.preventDefault();

    const access_token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    if (newPassword !== confirmNewPassword) {
      alert("Passwords don't match");
    } else {
      const data = await axios
        .post(
          baseUrl + "changepassword",
          {
            Password,
            newPassword,
            confirmNewPassword,
          },
          { headers }
        )
        .then((res) => {
          history.push("/admin/alladmins");
        });
    }
  };

  return (
    <>
      <h4 className="text-capitalize font-weight-bold my-3 text-dark">
        Update Admin Password
      </h4>
      <div className="update">
        <Link
          to={"/admin/profile"}
          type="button"
          className="badge badge-pill badge-info"
        >
          <FaRegCircleUser /> User Profile ➡
        </Link>
      </div>
      <div className="profile-container">
        <div className="profile-card">
          <form id="update_admin_form" onSubmit={(e) => addAdmin(e)}>
            <div className="form-group">
              <label htmlFor="passowrd">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder=" Previos Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
            </div>

            <div className="form-group ">
              <label htmlFor="passowrd">New Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder="New Password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                required
              />
            </div>

            <div className="form-group ">
              <label htmlFor="passowrd">Confirm New Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder=" Confirm New Password"
                onChange={(e) => {
                  setCPassword(e.target.value);
                }}
                required
              />
            </div>

            <button type="submit" className="button">
              Update
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
